<template>
    <div class="page" id="reorganize">
        <el-form class="searchForm query-form" size="small" ref="inputForm" :model="inputForm"
                 label-width="100px">
            <el-form-item label="任务状态" prop="jobState">
                <el-select v-model="inputForm.jobState"
                           placeholder="请选择状态"
                           style="width: 100%"
                           clearable>
                    <el-option
                            v-for="item in typeList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="标注模型" prop="modelId">
                <el-select v-model="inputForm.modelId"
                           placeholder="请选择标注模型"
                           style="width: 100%"
                           clearable>
                    <el-option
                            v-for="item in modelIdList"
                            :key="item.id"
                            :label="item.modelName"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="输入搜索" prop="keyWord">
                <el-input v-model="inputForm.keyWord"
                          placeholder="请输入关键字(限50字)"
                          @keyup.enter.native="getDataList(1,1)"
                          maxlength="50" clearable></el-input>
            </el-form-item>
            <el-form-item label="开始时间" prop="staterTime" class="dateBox">
                <el-date-picker
                        clearable
                        style="width: 100%"
                        v-model="inputForm.staterTime"
                        type="datetimerange"
                        range-separator="至"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间" prop="endTime" class="dateBox">
                <el-date-picker
                        clearable
                        v-model="inputForm.endTime"
                        style="width: 100%"
                        type="datetimerange"
                        range-separator="至"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="定时启动时间" prop="startUpTime" class="dateBox">
                <el-date-picker
                        clearable
                        v-model="inputForm.startUpTime"
                        type="datetimerange"
                        style="width: 100%"
                        range-separator="至"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label-width="20px" class="dateBox">
                <div class="flex_b_c">
                    <div>
                        <el-button size="small" type="primary" @click="getDataList(1,1,1)"
                                   icon="el-icon-search">查询
                        </el-button>
                        <el-button size="small" @click="resetting()" icon='el-icon-refresh-right'>重置</el-button>
                    </div>
                    <div>
                        <el-button type="primary" size="small"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:add`)"
                                   icon='el-icon-plus' @click="addData(0)">新增
                        </el-button>
                        <el-button icon="el-icon-delete" size="small" type="danger" @click="deleteDate()"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:delete`)">删除
                        </el-button>
                    </div>
                </div>
            </el-form-item>
        </el-form>

        <div class="bg-white">
            <el-table
                    :data="dataList"
                    size="small"
                    v-loading="loading"
                    ref="multipleTable"
                    height="calc(100vh - 375px)"
                    class="table"
                    :row-key="'id'"
                    :header-cell-style="{background:'#F9DFDF'}"
                    @selection-change="selectionChangeHandle"
            >
                <el-table-column :reserve-selection="true" type="selection" width="50"/>
                <el-table-column prop="jobName" label="任务名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="modelId" label="标注模型" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ getModelIdName(scope.row.modelId) }}
                    </template>
                </el-table-column>
                <el-table-column prop="startTime" label="开始时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="endTime" label="结束时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="startUpTime" label="定时启动时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="remake" label="任务描述" show-overflow-tooltip></el-table-column>
                <el-table-column prop="jobState" label="启动状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.jobState == 0 ? '已启动' : '未启动' }}
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button size="mini" type="text"
                                   @click="addData(1, scope.row,scope.$index)"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:detail`)">
                            详情
                        </el-button>
                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                   @click="addData(2, scope.row)"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:edit`)">
                            修改
                        </el-button>
                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                   @click="deleteDate(scope.row)"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:delete`)">
                            删除
                        </el-button>
                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                   @click="startUp(scope.row)"
                                   v-if="scope.row.jobState == 1"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:startUp`)">
                            启动
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="text_center">
                <el-pagination
                        @size-change="sizeChangeHandle"
                        @current-change="currentChangeHandle"
                        :current-page="current"
                        :page-sizes="[10, 20, 50, 100]"
                        :page-size="size"
                        :total="total"
                        background
                        layout="total, sizes, prev, pager, next, jumper"
                >
                </el-pagination>
            </div>
        </div>
        <add-auto-label ref="addAutoLabel" @refreshList="getDataList()"></add-auto-label>
    </div>
</template>

<script>
import AddAutoLabel from "@/views/modules/dyyg/imageProcessing/addAutoLabel.vue";

export default {
    name: "autoLabel",
    components: {AddAutoLabel},
    props: {
        typePage: {
            type: String,
            default: 'autoLabel',
        },
    },
    data() {
        return {
            inputForm: {
                modelId: '',
                jobState: '',

                staterTime: [],
                startTimeStart: '',
                startTimeEnd: '',

                endTime: [],
                endTimeStart: '',
                endTimeEnd: '',

                startUpTime: [],
                startUpTimeStart: '',
                startUpTimeEnd: '',

                keyWord: '',
                jobType: '0',
            },
            modelIdList: [],
            typeList: [
                {
                    value: '0',
                    label: '已启动',
                },
                {
                    value: '1',
                    label: '未启动',
                },
            ],

            dataListSelect: [],
            loading: false,
            dataList: [],

            pageNo2: 0,
            current: 1,
            size: 10,
            total: 0,
        }
    },

    mounted() {
        this.$axios(this.api.original.dyygmodelGetDyygModel, {
            modelState: 0,
            keyWord: '',
            current: 1,
            size: 99,
        }, 'post').then(res => {
            if (res.status) {
                this.modelIdList = res.data.records
            }
        })
        this.getDataList()
    },

    methods: {
        getModelIdName(id) {
            let row = this.modelIdList.filter(item => {
                return item.id == id
            })
            if (row.length) {
                return row[0].modelName
            }
        },

        //获取列表数据
        getDataList(type, dividePage) {
            if (type == 1) {
                this.current = 1
            }
            if (dividePage == 1) {
                this.$refs.multipleTable.clearSelection()
            }
            let searchData = JSON.parse(JSON.stringify(this.inputForm))
            if (searchData.staterTime && searchData.staterTime.length == 2) {
                searchData.startTimeStart = searchData.staterTime[0]
                searchData.startTimeEnd = searchData.staterTime[1]
            } else {
                searchData.startTimeStart = ''
                searchData.startTimeEnd = ''
            }
            if (searchData.endTime && searchData.endTime.length == 2) {
                searchData.endTimeStart = searchData.endTime[0]
                searchData.endTimeEnd = searchData.endTime[1]
            } else {
                searchData.endTimeStart = ''
                searchData.endTimeEnd = ''
            }
            if (searchData.startUpTime && searchData.startUpTime.length == 2) {
                searchData.startUpTimeStart = searchData.startUpTime[0]
                searchData.startUpTimeEnd = searchData.startUpTime[1]
            } else {
                searchData.startUpTimeStart = ''
                searchData.startUpTimeEnd = ''
            }
            this.$delete(searchData, 'staterTime')
            this.$delete(searchData, 'endTime')
            this.$delete(searchData, 'startUpTime')
            this.loading = true;
            this.$axios(this.api.original.dyygmodeljobGetDyygModelJob, {
                ...searchData,
                current: this.current,
                size: this.size,
            }, 'post').then(res => {
                if (res.status) {
                    this.dataList = res.data.records
                    this.$nextTick(() => {
                        this.$refs.multipleTable.doLayout()
                    })
                    this.total = parseInt(res.data.total)
                    if (this.dataList.length == 0 && this.current > 1) {
                        this.pageNo--
                        this.getDataList()
                    }
                } else {
                    this.$message.error('查询失败');
                }
                this.loading = false
            })
        },

        //num 0新增 1详情 2修改
        addData(num, row, index) {
            if (num == 2) {
                if (this.toDateSize(new Date(), row.modelStartUpTime)) {
                    this.$message.error('任务已启动，不支持修改！')
                    return
                }
            }
            this.$refs.addAutoLabel.init(num, row)
        },

        //时间大小比较
        toDateSize(date1, date2) {
            date1 = new Date(date1).getTime()
            date2 = new Date(date2).getTime()
            if (date1 > date2) {
                return true
            } else {
                return false
            }
        },

        deleteDate(row) {
            if (!row && !this.dataListSelect.length) {
                this.$message.warning('请至少选择一条数据')
                return
            }
            let ids
            if (row) {
                ids = [row.id]
            } else {
                ids = this.dataListSelect.map(item => {
                    return item.id
                })
            }
            this.$confirm(`确认删除所选数据?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios(this.api.original.dyygmodeljobRemoveById, ids, 'post').then(data => {
                    if (data && data.status) {
                        this.$message.success('删除成功')
                        this.getDataList('', 1)
                    } else {
                        this.$message.error(data.msg)
                    }
                })
            })
        },

        startUp(row) {
            this.$confirm(`确认启动所选数据?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                row.jobState = 0
                this.$axios(this.api.original.dyygmodelUpdateById, {
                    id: row.id,
                    jobState: row.jobState,
                }, 'put').then(data => {
                    if (data && data.status) {
                        this.$message.success('启动成功')
                        this.getDataList('', 1)
                    } else {
                        this.$message.error(data.msg)
                    }
                })
            })
        },

        //表格勾选数据
        selectionChangeHandle(val) {
            this.dataListSelect = val
        },

        // 展览每页数
        sizeChangeHandle(val) {
            this.size = val;
            this.getDataList(1, 1)
        },
        // 展览当前页
        currentChangeHandle(val) {
            this.current = val;
            this.getDataList('', 2);
        },

        //重置
        resetting() {
            this.$refs.inputForm.resetFields();
            this.getDataList(1, 1)
        },
    },
}
</script>

<style lang="scss" scoped>
.bg-white {
    overflow-y: auto !important;
}

.searchForm {
    .el-form-item--small.el-form-item {
        display: inline-block;
        width: 33%;
    }

    .dateBox.el-form-item--small.el-form-item {
        display: inline-block;
        width: 49%;
    }
}
</style>